html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: 'Boston-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Boston-Regular'), url('./fonts/BostonRegular.woff') format('woff');
}

body {
  margin: 0;
  font-family: Boston-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}